<template>
    <b-overlay
      :show="is_loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
       
                <validation-observer ref="account">
                    <b-form class="data-form">
                        <b-row>
                            <b-col cols="12">

                                <b-form-group
                                    label-for="first-name"
                                  >
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Ваше имя"
                                            rules="required"
                                        >
                                        <span class="text-muted" style="font-size: 12px;">{{$t('forms.labels.first-name')}}</span>                             
                                        <b-form-input
                                          id="first-name"
                                          :placeholder="$t('forms.placeholders.first-name')"
                                          v-model="firstName"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                 </b-form-group>

                            </b-col>

                            <b-col cols="12">

                                <b-form-group
                                    label-for="last-name"
                                  >
                                      <span class="text-muted" style="font-size: 12px;">{{$t('forms.labels.last-name')}}</span>      
                                      <b-form-input
                                        id="last-name"
                                        :placeholder="$t('forms.placeholders.last-name')"
                                        v-model="lastName"
                                      />
                                      
                                 </b-form-group>

                            </b-col>

                            <b-col cols="12">

                                <b-form-group
                                    label-for="email-address"
                                >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Электронная почта"
                                            rules="required|email"
                                        >
                            
                                            <span class="text-muted" style="font-size: 12px;">{{$t('forms.labels.email')}} <b-badge v-if="!$user.self.is_verify" variant="danger" style="font-size: 8px; margin-left: 5px;">{{$t('forms.confirmation-required')}}</b-badge></span>   
                                            <b-form-input
                                              id="email-address"
                                              :placeholder="$t('forms.placeholders.email')"
                                              v-model="mail"
                                            />
                                            
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            
                                        </validation-provider>
                                    
                                 </b-form-group>

                            </b-col>

                            <b-col cols="12" class="d-flex mt-2">
                                <showAt breakpoint="mediumAndAbove">
                                    <b-button
                                        variant="primary"
                                        type="submit"
                                        @click.prevent="save"
                                        class="ml-auto"
                                        pill
                                        style="height: 56px; font-size: 16px; border-radius: 16px;"
                                      >
                                        {{$t('forms.btns.save-change')}}
                                    </b-button>
                                </showAt>
                                <showAt breakpoint="small">
                                    <b-button
                                        variant="primary"
                                        type="submit"
                                        @click.prevent="save"
                                        block
                                        pill
                                        size="lg"
                                      >
                                        {{$t('forms.btns.save-change')}}
                                    </b-button>
                                </showAt>
                            </b-col>

                        </b-row>
                    </b-form>
                </validation-observer>
         
    </b-overlay>    
</template>

<script>

    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email } from '@validations'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    export default {

        data() {
            return {
                
                required,
                email,
                
                is_loading: false,
                
                firstName: this.$user.self.firstName,
                lastName : this.$user.self.lastName,
                mail: this.$user.self.email,
                // btc_address: this.$user.self.btc_address
            }
        },
        methods: {
            save( args ) {
              
                this.$refs.account.validate().then(success => {
                    if (success) {
                        this.is_loading = true;
                        this.$request.post("user.edit", {
                            firstName: this.firstName,
                            lastName: this.lastName,
                            email: this.mail,
                            // btc_address: this.btc_address
                        }).then( result => {
                            
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                title: this.$t('toasts.titles.change-data'),
                                text: this.$t('toasts.text.change-data'),
                                icon: 'CheckCircleIcon',
                                variant: 'success',
                                }
                            });
                            
                            this.is_loading = false;
                        }).catch(err => {
                            
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                   title: this.$t('toasts.titles.error'),
                                  text: err.message,
                                  icon: 'AlertCircleIcon',
                                  variant: 'danger',
                                }
                            });
                            
                            this.is_loading = false;
                            
                        });
                    }   
                });
                    
            },
        },
        components: {
            ValidationProvider,
            ValidationObserver
        },
        watch: {

        },
        mounted() {

        }

    }

</script>