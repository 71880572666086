<template>

    <b-card no-body bg-variant="transparent" class="shadow-none">
        
        <b-card-text v-if="!isModalView">
            <p class="m-0 text-muted">{{$t('forms.personal-data-necessary')}}</p>
            <p class="text-muted">{{$t('forms.conclusion-contract')}}</p>

            <p class="text-danger">{{$t('forms.verification-personal')}}</p>
        </b-card-text>
                    
        <b-tabs v-model="userType" pills >
            <b-tab :title="$t('forms.individual')" >
                <individual-type @changePersonal="changePersonal" />
            </b-tab>
            <b-tab v-if="personal.country.id == 181" :title="$t('forms.entity')" >
                <entity-type />
            </b-tab>
        </b-tabs>
            
    </b-card>
        
</template>

<script>
    
    import IndividualType from "./types/individual"
    import EntityType from "./types/entity"
    
    import User from "@/modules/user/"

    export default {

        data() {
            return {
                userType : User.self.type ? User.self.type : 0,

                personal: {
                    
                    last_name: "",
                    first_name: "",
                    middle_name: "",
                    
                    gender: { 
                        id: 1, 
                        name: this.$i18n.locale === 'ru' ? "Мужской" : "Female" 
                    },
                    
                    country: {
                        id: 181,
                        name: "Россия",
                        phonecode: 7
                    },
                    
                    birthday: "",
                    
                    passport_series: "",
                    passport_number: "",
                    passport_date_of_issue: "",
                    passport_department_code: "",
                    passport_issued_by: "",
                    
                    phone_number: "",
                    
                    inn: ""
                },
            }
        },
        props: {
            isModalView: {
                type: Boolean,
                default: false
            },
            doneButtonText: {
                type: String,
                default: "Готово"
            }
        },
        methods: {

            changePersonal(personal) {
                this.personal = personal
            }

        },
        
        computed: {
            
        },
        
        components: {
            IndividualType,
            EntityType
        },
        watch: {

        },
        mounted() {

           
        }

    }

</script>
