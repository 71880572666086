<template>
    <b-card>
        <b-card-title>
            {{$t('settings.profile-information')}}
        </b-card-title>

        <b-tabs pills class="gray-nav">
            <b-tab :title="$t('settings.main-information')">
                <account-info />
            </b-tab>
            <b-tab :title="$t('settings.personal-information')">
                <account-personal />
            </b-tab>
        </b-tabs>

    </b-card>
</template>

<script>

    import AccountInfo from "./account-info"
    import AccountPersonal from "./account-personal"
    
    export default {

        data() {
            return {}
        },
        methods: {

        },
        components: {
            AccountInfo,
            AccountPersonal,
        },
        watch: {

        },
        mounted() {

        }

    }

</script>